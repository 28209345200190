.App {
  color: #002a5a;
}

.vz-bkg {
  background-color: #002a5a;
}

.vz-bkg-white {
  background-color: #ffffff;
}

.vz-border {
  border: solid #002a5a 1px;
}

.vz-border-top {
  border-top: solid #002a5a 1px;
}

.vz-border-white {
  border: solid #ffffff 1px;
}

.vz-border-orange {
  border: solid #BC5930 1px;
}

.vz-img-sm {
  height: 24px;
  width: 24px;
  stroke: #002a5a;
  fill: #002a5a;
}

.vz-img-logo {
  height: 48px;
  width: 48px;
  stroke: #002a5a;
  fill: #002a5a;
}

.vz-img-white-logo {
  height: 48px;
  width: 48px;
  stroke: #ffffff;
  fill: #ffffff;
}

.vz-img {
  height: 96px;
  width: 96px;
  stroke: #002a5a;
  fill: #002a5a;
}

.vz-img-white {
  height: 96px;
  width: 96px;
  stroke: #ffffff;
  fill: #ffffff;
}

.vz-img_lg {
  height: 256px;
  width: 256px;
  stroke: #002a5a;
  fill: #002a5a;
}

.vz-font {
  color: #002a5a !important;
  font-size: 1em !important;
}

.vz-font-white {
  color: #ffffff !important;
  font-size: 1em !important;
}

.vz-font-sm {
  color: #002a5a !important;
  font-size: 0.8em !important;
}

.vz-font-white-sm {
  color: #ffffff !important;
  font-size: 0.8em !important;
}

.vz-font-lg {
  color: #002a5a !important;
  font-size: 1.25em !important;
  font-weight: bold !important;
}

.vz-font-white-lg {
  color: #ffffff !important;
  font-size: 1.25em !important;
  font-weight: bold !important;
}

.vz-font-xl {
  color: #002a5a !important;
  font-size: 1.8em !important;
  font-weight: bold !important;
}

.vz-font-white-xl {
  color: #ffffff !important;
  font-size: 1.8em !important;
  font-weight: bold !important;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:hover {
  background-color: #BC5930 !important;
  border-color: #BC5930 !important;
}

.container-fluid-sm {
  margin-right: auto;
  margin-left: auto;
  max-width: 384px;
  min-width: 384px;
}

.container-fluid-lg {
  margin-right: auto;
  margin-left: auto;
  width: 960px;

}

a:link,
a:active,
a:visited,
a:hover {
  text-decoration: none;
  color: #002a5a;
}